@use '../../colors';

.main {
    border-radius: 3px;
    line-height: 1;
    padding: 16px;
    text-align: left !important;
    display: flex;
}

.error {
    background-color: colors.$red3;
    color: colors.$red_dark;
}

.success {
    background-color: colors.$green3;
    color: colors.$green11;
}

.warning {
    background-color: colors.$yellow4;
    color: colors.$yellow12;
}

.icon {
    height: 16px;
    transform: scale(110%);
    width: 16px;
}
