@use '../colors';

.wrapper {
  padding: 6px 10px;
}

.item {
  display: inline-block;
  font-size: 13px;
  padding: 7px 16px;
  border-radius: 32px;
  background-color: colors.$blue6;
  cursor: pointer;
  transition: background-color .1s ease-out;

  &:hover {
    background-color: colors.$blue5;
  }

  &:not(:last-child) {
    margin-right: 12px;
    margin-bottom: 5px;
  }

  &--selected {
    background-color: colors.$blue !important;
    color: white;
  }

  &--error {
    background-color: colors.$red5 !important;
  }

}

.label {
  font-size: 13px;
  margin: 0 0 12px;
}

.itemsWrapper {
  margin-left: -10px;
}

.column {
  display: flex;
  flex-direction: column;
  > .item {
    margin-right: 0;
  }
}