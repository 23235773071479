@use "ui/mixins";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.2; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

html,
body {
  font-family: 'Roboto', sans-serif;
  max-width: 100vw;
  //overflow-x: hidden;
  scroll-behavior: smooth !important;
}

body {
  background: #f6f5f8;
  color: #000;
  font-size: 15px;
  font-weight: 400;
  -webkit-font-smoothing: subpixel-antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-variant-ligatures: none;
  font-variant-ligatures: none;
  text-rendering: optimizeLegibility;
  margin: 0; // Remove the margin in all browsers.
}

@include mixins.scrollBar;

img {
  height: auto;
  max-width: 100%;
  width: 100%;
}

//@media (prefers-color-scheme: dark) {
//  html {
//    color-scheme: dark;
//  }
//}

h1,
h2,
h3,
h4 {
  font-size: 24px;
  font-weight: 500;
}

p {
  font-size: inherit;
  font-weight: 400;
}

b,
strong {
  font-weight: 500;
}
/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */
button,
input {
  /* 1 */
  overflow: visible;
}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */
button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type='button'],
[type='reset'],
[type='submit'] {
  -webkit-appearance: button;
}

a,
button {
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  text-decoration: none;
}

ul {
  list-style-type: none;

  li {
    &::marker {
      display: none;
    }
  }
}
/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type='search'] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}
/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}
