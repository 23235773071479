@use '../colors';

div.wrapper {
  grid-template-columns: 40px 1fr;
  column-gap: 12px;
  font-size: 13px;
}

.buttonWrapper {
  background-color: colors.$grey3;
  padding: 2px;
  border-radius: 15px;
  width: 36px;
  height: 19px;
  position: relative;
  cursor: pointer;
  box-shadow: colors.$shadow1;
  transition: .2s;

  &--active {
	background-color: colors.$blue;
  }
}

.child {
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 15px;
  transition: .2s;
  .buttonWrapper--active & {
	transform: translateX(15px);
  }
}