@use '../colors';

.wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 900;
  background-color: colors.$grey1;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: show .2s ease-out;
}

.wrapperBlur {
  backdrop-filter: blur(10px);
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.body {
  overflow: hidden;
}
.close {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
}
.window {
  z-index: 2;
  padding: 10px;
  background: white;
  border-radius: 4px;
  box-shadow: colors.$shadow4;
  min-width: 200px;
  text-align: left;
}
.closeIcon {
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: colors.$grey5;
  transition: color .2s ease-out;
  :hover {
    color: #000;
  }
}
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 17px;
}

.sizeXS {
  width: 300px;
}

.sizeS {
  width: 400px;
}

.sizeM {
  width: 700px;
}

.sizeL {
  width: 1250px;
  height: 90vh;
}