@use "../../colors";

.root {
  border: 1px colors.$grey3 dashed;
  padding: 8px;
  border-radius: 5px;
  transition: background-color .3s ease-in-out;
  cursor: pointer;
  background-color: unset;

  &:hover {
    background-color: colors.$grey_light2;
  }

  &:hover:active {
    background-color: colors.$grey_light4;
  }
}