@use '../colors';

.item {
  position: relative;
  //height: 40px;
  background: colors.$blue6;
  border-radius: 3px;
  padding: 6px 11px;
  border: 2px solid transparent;
  transition: all .2s ease-out;
  cursor: text;

  .big & {
    height: auto;
  }

  &--error {
    background: colors.$red5;
  }

  &--focused {
    border-color: colors.$blue;
    background: white;
  }


  & input, & > select, & > textarea {
    background: transparent;
    border: none;
    outline: none !important;
    opacity: 0;
    transition: all .2s ease-out;
    padding-top: 23px;
    font-size: 15px;
    width: 100%;
    font-family: 'Roboto', sans-serif;


    .small &{
      padding-top: 3px;
    }
  }

  &--active input,
  &--active select,
  &--active textarea {
      opacity: 1;
  }

}
.label {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin: auto;
  font-size: 15px;
  color: colors.$grey8;
  line-height: 1;
  transition: all .2s ease-out;

  textarea + & {
    top: 21px;
  }

  .item--focused & {
    color: black;
  }

  .item--active & {
    top: 8px;
    font-size: 13px;
    transform: translateY(0);
  }
}
