div.wrapper {
  background-color: white;
  font-family: 'Fira Sans', sans-serif !important;

  :global(.ql-container) {
    font-family: 'Fira Sans', sans-serif !important;
  }

  :global(.gl-editor) {
    min-height: 200px;
    max-height: 40vh;
  }

  img {
    width: unset !important;
  }

  p {
    line-height: 1.5;
  }

  b, strong {
    font-weight: 500;
  }

  h2, h3 {
    background: #edf2f8;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -1px rgba(0, 0, 0, .12);
    display: block;
    font-size: 16px;
    line-height: 1.25;
    margin: 30px -7px 25px;
    padding: 10px 2%;
    text-align: center;
  }
}