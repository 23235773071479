.root {
  position: fixed;
  left: 0;
  right: 0;
  max-width: 400px;
  top: 40%;
  transform: translateY(-50%);
  margin: auto;
}

.logo {
  width: 75%;
  margin: auto;
  padding-bottom: 35px;
}