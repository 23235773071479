@use "sass:color";

$grey8: rgba(0, 0, 0, 0.8);
$grey7: rgba(0, 0, 0, 0.7);
$grey5: rgba(0, 0, 0, 0.5);
$grey3: rgba(0, 0, 0, 0.3);
$grey1: rgba(0, 0, 0, 0.1);
//$grey05: rgba(0, 0, 0, 0.05);

$gray8: #333333;


$grey_light: #ebebeb;
$grey_light2: rgb(226, 226, 226);
$grey_light3: rgb(219, 219, 219);
$grey_light4: rgb(194, 194, 194);
$grey_light6: #808080;

$blue: #517dba;
$blue1: color.adjust($blue, $lightness: 90%);
$blue2: color.adjust($blue, $lightness: 80%);
$blue3: color.adjust($blue, $lightness: 70%);
$blue5: color.adjust($blue, $lightness: 40%);
$blue6: color.adjust($blue, $lightness: 35%);
$blue8: color.adjust($blue, $lightness: 20%);
$blue11: color.adjust($blue, $lightness: -10%);

// Everest colors
$main_bg: #f6f5f8;

$blue_everest: #105bb5;
$blue_gradient: linear-gradient(to top left,$blue_everest,$blue_everest,#4B80C1);
$blue_bg: #124f8a;

//$customBlue: dodgerblue;
//$customGreen: forestgreen;
//$customRed: orangered;

$green: #69AF93;
$green2: color.adjust($green, $lightness: 20%);
$green3: color.adjust($green, $lightness: 30%);
$green11: color.adjust($green, $lightness: -10%);
$green_clicked: #4B7D69;
//$green_hover: #e3fcf2;
//$green_disabled: #AABDB6;

$yellow: #ddc900;
$yellow_dark: #af9f00;
$yellow4: color.adjust($yellow, $lightness: 40%);
$yellow12: color.adjust($yellow, $lightness: -20%);
//$yellow3: rgba(242, 227, 79, 0.3);
//$yellow5: rgba(242, 227, 79, 0.5);

$red: #D5565D;
$red3: rgba(213, 86, 93, 0.3);
$red5: rgba(213, 86, 93, 0.5);
$red6: rgba(213, 86, 93, 0.6);
$red_dark: #971515;

$shadow1: 0 1px 1px 0 rgba(0, 0, 0, 0.04), 0 2px 1px -1px rgba(0, 0, 0, 0.02),
0 1px 3px 0 rgba(0, 0, 0, 0.2);
$shadow2: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12),
0 1px 5px 0 rgba(0, 0, 0, 0.2);
$shadow3: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.12),
0 1px 8px 0 rgba(0, 0, 0, 0.2);
$shadow4: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
0 2px 4px -1px rgba(0, 0, 0, 0.2);
$shadow6: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
0 3px 5px -1px rgba(0, 0, 0, 0.2);
//$shadow8: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12),
//0 5px 5px -3px rgba(0, 0, 0, 0.2);
//$shadow9: 0 9px 12px 1px rgba(0,0,0,0.14), 0 3px 16px 2px rgba(0,0,0,0.12), 0 5px 6px -3px rgba(0,0,0,0.20);
//$shadow12: 0 12px 17px 2px rgba(0, 0, 0, 0.14),
//0 5px 22px 4px rgba(0, 0, 0, 0.12), 0 7px 8px -4px rgba(0, 0, 0, 0.2);

$avocado: #618B25;
$pink: #DE4D86;
$orange: rgb(255, 165, 0);
$orangeDark: #DB8F00;