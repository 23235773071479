@use '../colors';

.wrapper {
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  transition: background-color .2s ease-in-out;
  width: 140px;

  &:hover {
	background-color: colors.$blue5;
  }
}
.icon {
  height: 45px;
  width: 45px;
  margin: 0 auto 10px;
  color: colors.$blue11;
}