@use "../../colors";

.root {
  border-top: 2px colors.$blue8 solid;
  border-bottom: 2px colors.$blue8 solid;

  &[open] {
    padding-bottom: 16px;

    .label {
      border-bottom: 2px colors.$blue6 solid;
      margin-bottom: 16px;
      background-color: colors.$blue5;
    }

    .toggleIcon {
      transform: translateX(50%);
      rotate: -90deg;
    }
  }

  & + .root {
    border-top: unset;
  }
}

summary.label {
  position: relative;
  padding: 9px 8px;
  font-size: 16px;
  transition: background-color .3s ease-in-out;
  align-items: center;
  display: flex;
  gap: 8px;
  cursor: pointer;

  &:hover {
    background-color: colors.$blue6;
  }


}

.icon {
  color: colors.$blue;
  height: 24px;
  width: 24px;
  transition: color .3s ease-in-out;
}

.toggleIcon {
  color: colors.$blue;
  position: absolute;
  right: 8px;
  height: 20px;
  width: 20px;
  top: 50%;
  transform: translateX(-50%);
  rotate: 90deg;
  transition: color .3s ease-in-out;
}

.error {
  .icon, .toggleIcon {
    color: colors.$red_dark
  }
  .label {
    background-color: colors.$red5 !important;

    &:hover {
      background-color: colors.$red6 !important;
    }
  }
}