.title {
  padding-top: 6px;
  padding-left: 9px;
}

.icon {
  width: 20px;
  height: 20px;
}

.fieldWrapper {
  display: flex;
}

.inputField {
  flex-grow: 1;
}