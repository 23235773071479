.label {
  font-size: 13px;
  margin-bottom: 5px;
}

.text {
  margin-left: 5px;
}

.inline {
  font-size: 16px;
  display: inline-block;
}

.capitalize {
  text-transform: capitalize;
}