.item {
  display: grid;
  grid-template-columns: repeat(12, 1fr);

  &.flex {
    display: flex;
  }
}

.column {
  grid-template-columns: 1fr;
}

.align-middle {
  align-items: center;
}

.align-top {
  align-items: flex-start;
}

.justify-center {
  justify-content: center;
}

$gutters: 4, 8, 12, 16;

@each $gutter in $gutters {
  .gutter-#{$gutter} {
    gap: #{$gutter}px;
  }
}