@use '../../colors';

.main {
    align-items: center;
    background: colors.$blue;
    border: 1.5px solid colors.$blue;
    border-radius: 3px;
    box-shadow:
        0 1px 1px 0 rgba(0, 0, 0, 0.14),
        0 2px 1px -1px rgba(0, 0, 0, 0.12),
        0 1px 3px 0 rgba(0, 0, 0, 0.2);
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    gap: 8px;
    justify-content: center;
    padding: 12px;
    text-align: center;
    transition: all 0.2s ease-out;
    font-size: 15px;

    &:hover,
    &:focus {
        background: #7597c8;
        border-color: #7597c8;
        box-shadow:
            0 2px 2px 0 rgba(0, 0, 0, 0.14),
            0 3px 1px -2px rgba(0, 0, 0, 0.12),
            0 1px 5px 0 rgba(0, 0, 0, 0.2);
        outline: none;
    }
}

.main.small {
    padding: 8px;
}

.main.outline {
    background: transparent;
    border-color: colors.$blue;
    box-shadow: none;
    color: colors.$blue;

    &:hover,
    &:focus {
        background: colors.$blue2;
        box-shadow: none;
    }

    &:active {
        background: colors.$blue3;
    }
}

.main.soft {
    background: colors.$blue6;
    border-color: colors.$blue6;
    box-shadow: none;
    color: #000;

    &:hover,
    &:focus {
        background: colors.$blue5;
        border-color: colors.$blue5;
        box-shadow: none;
    }

    &:active {
        background: colors.$blue8;
        border-color: colors.$blue8;
    }
}

.main.simple {
    background: none;
    border-color: transparent;
    box-shadow: none;
    color: colors.$blue;
    font-weight: 500;

    &:hover,
    &:focus {
        color: colors.$blue8;
    }

    &:active {
        color: colors.$blue11;
    }
}

.main.error {
    background: colors.$red5;
    border-color: colors.$red5;
    box-shadow: none;
    color: #000;

    &:hover,
    &:focus {
        background: colors.$red3;
        border-color: colors.$red3;
        box-shadow: none;
    }

    &:active {
        background: colors.$red6;
        border-color: colors.$red6;
    }
}

button.main:disabled {
    background: colors.$grey_light3;
    color: #fff !important;
    border-color: colors.$grey_light3;
    cursor: not-allowed;
    box-shadow: none;
}


button.main.loading {
    animation: loading 0.8s linear infinite;
    background: linear-gradient(
        75deg,
        colors.$blue 30%,
        colors.$blue11 32%,
        colors.$blue11 68%,
        colors.$blue 70%
    ) !important;
    background-size: 35px !important;
    color: #fff !important;
    cursor: progress;
    opacity: 1 !important;
}

@keyframes loading {
    0% {
        background-position-x: 0;
    }

    100% {
        background-position-x: 35px;
    }
}
