@use '../colors';

.title {
  padding: 10px 0 10px 16px;
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 500;
  position: relative;
  z-index: 15;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.infoWrapper {
  position: relative;

  &:hover .tip {
    width: max-content;
    padding: 5px 10px;
    background-color: colors.$gray8;
    transition: opacity .3s ease-out;
    opacity: 1;
  }
}

.info {
  height: 15px;
  width: 15px;
  vertical-align: middle;
  margin-left: 8px;
}

.tip {
  font-weight: 400;
  max-width: 400px;
  width: 0;
  position: absolute;
  top: 6px;
  left: calc(100% + 5px);
  margin: auto;
  background-color: black;
  color: white;
  line-height: 1;
  padding: 0;
  overflow: hidden;
  opacity: 0;
  border-radius: 3px;
  font-size: 15px;
  z-index: 2;
}

.content {
  max-width: 1250px;
  padding-left: 16px;
  margin-bottom: 5vh;
}