@use 'colors';

@mixin mq-tablet-landscape-up {
  @media (min-width: 960px) {
    @content;
  }
}
@mixin mq-tablet-landscape-down {
  @media (max-width: 959px) {
    @content;
  }
}
@mixin mq-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin mq-tablet-portrait-down {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin mq-tablet-up {
  @media (min-width: 600px) {
    @content;
  }
}
@mixin mq-tablet {
  @media (min-width: 600px) and (max-width: 1279.9999px) {
    @content;
  }
}
@mixin mq-desktop {
  @media (min-width: 1280px) {
    @content;
  }
}
@mixin mq-mobile {
  @media (max-width: 599px) {
    @content;
  }
}
@mixin mq-desktop-large {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin scrollBar {
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #f6f2ff;
  }

  ::-webkit-scrollbar {
    width: 7px;
    background-color: #f6f2ff;
    padding: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #c1c1c1;
    border-radius: 5px;

    &:hover {
      background-color: colors.$red;
    }
  }
}