@use '../mixins';

.item {
    align-content: center;

  &.top {
    align-content: start;
  }
}

$col-1: 8.33333%;
$cols: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12;

@each $col in $cols {
  .xs-#{$col} {
    grid-column: span #{$col};
  }
}

@include mixins.mq-tablet-portrait-up {
  @each $col in $cols {
    .md-#{$col} {
      grid-column: span #{$col};
    }
  }
}

@include mixins.mq-desktop {
  @each $col in $cols {
    .l-#{$col} {
      grid-column: span #{$col};
    }
  }
}

@include mixins.mq-desktop-large {
  @each $col in $cols {
    .xl-#{$col} {
      grid-column: span #{$col};
    }
  }
}
