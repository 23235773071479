@use '../colors';

.root {
  font-size: 13px;
  padding: 7px 16px;
  border-radius: 32px;
  background-color: colors.$blue6;
  display: inline;

  &.red {
    background-color: colors.$red6;
  }

  &.green {
    background-color: colors.$green2;
  }
}
