div.itemRow {
  grid-column: 1 / -1;
  grid-template-columns: 1fr 20px 1fr 40px;
}

.addButton {
  grid-column: 1 / -1;
}

.minus {
  align-content: center;
  justify-content: center;
  text-align: center;
}

.icon {
  width: 18px;
  height: 18px;
}