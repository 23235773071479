@use '../../colors'
;
.root {
  .header {
    font-weight: 500;
    background-color: white;
  }

  .row {
    display: flex;
    padding: 12px 8px;
    border-bottom: 1px colors.$grey_light3 solid;
    gap: 16px;
  }

  .rowItem {
    align-items: center;

    &:hover {
      background-color: colors.$blue5;
    }
  }

  .loadMore {
    margin-top: 16px;
  }
}