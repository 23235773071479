@use "../../colors";

.root {
  cursor: pointer;
  transition: color .2s ease-in-out;

  &:hover {
    color: colors.$blue
  }

  &:active {
    color: colors.$blue11;
  }
}