@use '../../mixins';

.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #f6f5f8;
    z-index: 10;
    animation: showIn 0.1s ease-in-out;
}

.loader,
.loaderDsk {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
}

.loader {
    width: 50px;
    height: 50px;
    animation: loader 0.8s -0.3s ease-in-out infinite;

    @include mixins.mq-tablet-up {
        display: none;
    }
}

.loaderDsk {
    display: none;
    width: 150px;
    height: 150px;

    &:after,
    &:before {
        content: '';
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center center;
    }

    &:before {
        box-shadow:
            inset 0 20px 0 rgba(7, 49, 230, 0.6),
            inset 20px 0 0 rgba(7, 113, 240, 0.6),
            inset 0 -20px 0 rgba(110, 209, 0, 0.6),
            inset -20px 0 0 rgba(254, 237, 1, 0.6);
        animation: rotate-before 2s -0.5s linear infinite;
    }

    &:after {
        box-shadow:
            inset 0 20px 0 rgba(230, 42, 7, 0.6),
            inset 20px 0 0 rgba(240, 9, 7, 0.6),
            inset 0 -20px 0 rgba(240, 7, 232, 0.6),
            inset -20px 0 0 rgba(173, 7, 230, 0.6);
        animation: rotate-after 2s -0.5s linear infinite;
    }

    @include mixins.mq-tablet-up {
        display: block;
    }
}

@keyframes showIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes loader {
    0% {
        transform: rotateZ(0deg);
    }

    100% {
        transform: rotateZ(360deg);
    }
}

@keyframes rotate-after {
    0% {
        transform: rotateZ(0deg) scaleX(1) scaleY(1);
    }

    50% {
        transform: rotateZ(180deg) scaleX(0.82) scaleY(0.95);
    }

    100% {
        transform: rotateZ(360deg) scaleX(1) scaleY(1);
    }
}

@keyframes rotate-before {
    0% {
        transform: rotateZ(0deg) scaleX(1) scaleY(1);
    }

    50% {
        transform: rotateZ(-180deg) scaleX(0.95) scaleY(0.85);
    }

    100% {
        transform: rotateZ(-360deg) scaleX(1) scaleY(1);
    }
}
