@use '../colors';

.message {
  font-size: 13px;
  text-align: left;
  padding-left: 11px;
  padding-top: 4px;
  color: colors.$red_dark;
}

.warning {
  color: colors.$yellow_dark;
}

.info {
  color: #000;
}

.noPadding {
  padding: 0;
}
