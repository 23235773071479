div.root {
  grid-template-columns: 1fr 1fr;
}
div.rootDocs {
  grid-template-columns: repeat(3, 1fr);
}
.addButton {
  grid-column: 1 / -1;
}

.input {
  display: none;
}

div.card {
  grid-template-columns: 1fr 1fr;
}

.deleteButton {
  align-self: center;
}